














































































































































































































































































































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import {
  Vue, Component, Watch,
} from 'vue-property-decorator';
import {
  Adoption, Apiary, ExpiredAdoption, Hive, User, UserDetails,
} from '@/types';
import moment from 'moment';

moment.locale('it');

@Component({
  components: {
    Progress: () => import('@/components/Progress.vue'),
    // UserCredits: () => import('@/components/UserCredits.vue'),
    // UserAdoptions: () => import('@/components/UserAdoptions.vue'),
    // AddUserDialog: () => import('@/components/AddUserDialog.vue'),
  },
})
export default class AdoptionsAdminDialog extends Vue {
  users: UserDetails[] = [];
  adoptions: Adoption[] = [];
  expiredAdoptions: ExpiredAdoption[] = [];

  dialog = false
  loading = false

  tab: any = null

  adoptionsHeaders = [
    { text: 'Nome', value: 'name', sortable: false },
    { text: 'Arnia/Apiario', value: 'hive', sortable: false },
    { text: 'Favi', value: 'honeycombs', sortable: false },
    {
      text: 'Progresso',
      value: 'progress',
      sortable: false,
      align: 'center',
      width: '200px',
    },
    { text: 'Miele consegnato', value: 'delivered', sortable: false },
    {
      text: '',
      value: 'action',
      sortable: false,
      align: 'end',
    },
  ]

  expiredAdoptionsHeaders = [
    { text: 'Nome', value: 'name', sortable: false },
    { text: 'Arnia/Apiario', value: 'hive', sortable: false },
    { text: 'Favi', value: 'honeycombs', sortable: false },
    { text: 'Data scadenza', value: 'deletedAt', sortable: false },
    { text: 'Miele consegnato', value: 'delivered', sortable: false },
    {
      text: '',
      value: 'action',
      sortable: false,
      align: 'end',
    },
  ]

  get value(): boolean {
    return this.$store.getters.adoptionsAdminDialog;
  }

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get isLoggedIn(): boolean {
    return this.currentUser.loggedIn;
  }

  get apiaries(): Apiary[] {
    return this.$store.getters.apiaries;
  }

  get hives(): Hive[] {
    return this.$store.getters.hives;
  }

  get filteredAdoptions(): Adoption[] {
    return this.adoptions
      // eslint-disable-next-line arrow-body-style, @typescript-eslint/no-unused-vars
      ? this.adoptions.filter((a) => {
        return true;
      })
      : [];
  }

  get filteredExpiredAdoptions(): ExpiredAdoption[] {
    return this.expiredAdoptions
      // eslint-disable-next-line arrow-body-style, @typescript-eslint/no-unused-vars
      ? this.expiredAdoptions.filter((a) => {
        return true;
      })
      : [];
  }

  @Watch('value')
  async onValueChanged(val: boolean) {
    console.log(`${this.constructor.name}: onValueChanged()`); // eslint-disable-line no-console

    if (val) {
      // Recupera l'elenco delle adozioni attive e di quelle scadute
      this.loading = true;
      this.users = await this.$store.dispatch('getUsers');
      this.adoptions = await this.$store.dispatch('getAdoptions');
      this.expiredAdoptions = await this.$store.dispatch('getExpiredAdoptions');
      this.loading = false;
    }

    this.dialog = val;
  }

  userDetails(uid: string): UserDetails | null {
    return this.users.find((u) => u.id === uid) ?? null;
  }

  userFullName(uid: string): string {
    return `${this.userDetails(uid)?.firstName} ${this.userDetails(uid)?.lastName}`;
  }

  userEmail(uid: string): string {
    return `${this.userDetails(uid)?.email}`;
  }

  apiary(apiaryId: string): Apiary | null {
    return this.apiaries.find((a) => a.id === apiaryId) ?? null;
  }

  apiaryName(apiaryId: string): string {
    return this.apiary(apiaryId)?.name ?? '';
  }

  apiaryHives(apiaryId: string): Hive[] {
    return this.$store.getters.apiaryHives(apiaryId);
  }

  hive(hiveId: string): Hive | null {
    return this.hives.find((h) => h.id === hiveId) ?? null;
  }

  hiveName(hiveId: string): string {
    return this.hive(hiveId)?.name ?? '';
  }

  mDate(date: Date | null | undefined): string | null { return date ? moment(date).format('L') : null; }

  adoptionStartDate(adoption: Adoption): string {
    return this.mDate(adoption?.startDate.toDate()) ?? '-';
  }

  adoptionEndDate(adoption: Adoption): string {
    return this.mDate(adoption?.endDate.toDate()) ?? '-';
  }

  adoptionLength(adoption: Adoption): number {
    return adoption ? moment(adoption.endDate.toDate()).diff(moment(adoption.startDate.toDate()), 'days') : 0;
  }

  adoptionDaysLeft(adoption: Adoption): number {
    return adoption ? moment(adoption.endDate.toDate()).diff(moment(new Date()), 'days') : 0;
  }

  percent(adoption: Adoption): number {
    return 100 * (
      (this.adoptionLength(adoption) - this.adoptionDaysLeft(adoption))
        / this.adoptionLength(adoption)
    );
  }

  progressText(adoption: Adoption): string {
    return `${this.adoptionDaysLeft(adoption)} giorni al termine`;
  }

  progressColor(adoption: Adoption): string {
    return this.isEnding(adoption) ? '#D15555' : '#F4BC46';
  }

  isEnding(adoption: Adoption): boolean {
    return this.adoptionDaysLeft(adoption) < 30;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    console.log(`${this.constructor.name}: onDialogChanged()`); // eslint-disable-line no-console

    if (this.value !== val) {
      this.$store.dispatch('toggleDialog', 'adoptionsAdmin');
    }

    // Scrolla in cima alla finestra
    // Questo metodo funziona solo per le dialog scrollabili
    if (val) {
      this.$nextTick(() => {
        const element = document.querySelector('.v-dialog--active > .v-card > .v-card__text');
        if (element) this.$vuetify.goTo(0, { container: element as HTMLElement, duration: 0 });
      });
    }
  }

  async onTabChange(val: number) {
    console.log(`${this.constructor.name}: onTabChange() val =`, val); // eslint-disable-line no-console
  }

  onCancel() {
    this.dialog = false;
  }

  // onDelete() {
  //   this.dialog = false;
  // }

  async onToggleDelivered(adoption: Adoption | ExpiredAdoption) {
    console.log(`${this.constructor.name}: onToggleDelivered() adoption.id = ${adoption.id}`); // eslint-disable-line no-console

    this.loading = true;

    // Verifica se `adoption` è un'adozione attiva o scaduta
    if ('data' in adoption) {
      // È un'adozione scaduta
      console.log(`${this.constructor.name}: onToggleDelivered() ExpiredAdoption`); // eslint-disable-line no-console

      try {
        await this.$store.dispatch('updateExpiredAdoptionDelivery', { expiredAdoptionId: adoption.id, delivered: !adoption.data.delivered });
      } catch (error) {
        console.error(`${this.constructor.name}: onToggleDelivered() error =`, error); // eslint-disable-line no-console
      }

      const updatedExpiredAdoption: ExpiredAdoption = await this.$store.dispatch('getExpiredAdoptionById', { id: adoption.id });
      const index = this.expiredAdoptions.findIndex((a) => a.id === adoption.id);
      this.expiredAdoptions.splice(index, 1, updatedExpiredAdoption);
    } else {
      // È un'adozione attiva
      console.log(`${this.constructor.name}: onToggleDelivered() Adoption`); // eslint-disable-line no-console

      try {
        await this.$store.dispatch('updateAdoptionDelivery', { adoptionId: adoption.id, delivered: !adoption.delivered });
      } catch (error) {
        console.error(`${this.constructor.name}: onToggleDelivered() error =`, error); // eslint-disable-line no-console
      }

      const updatedAdoption: Adoption = await this.$store.dispatch('getAdoptionById', { id: adoption.id });
      const index = this.adoptions.findIndex((a) => a.id === adoption.id);
      this.adoptions.splice(index, 1, updatedAdoption);
    }

    this.loading = false;
  }

  onUserDetails(uid: string) {
    console.log(`${this.constructor.name}: onUserDetails() uid = ${uid}`); // eslint-disable-line no-console

    // Preseleziona l'utente
    this.$store.dispatch('setDialogInitialUser', this.userDetails(uid));

    // Mostra il dialog con i dettagli dell'utente
    this.$store.dispatch('openDialog', 'adoptionUserDetails');
  }

  onHiveDetails(hiveId: string) {
    console.log(`${this.constructor.name}: onHiveDetails() hiveId = ${hiveId}`); // eslint-disable-line no-console

    // this.$store.dispatch('selectHive', adoption.hiveId);
    // this.$store.dispatch('openDialog', 'hiveDetails');
  }
}
