var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-adoptions-admin-dialog"},[_c('v-dialog',{ref:"dialog",attrs:{"scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"dialog-card"},[_c('h1',{staticClass:"dialog-name"},[_vm._v("Gestione adozioni")]),_c('div',{staticClass:"dialog-descr body-1 pb-4 mt-4"},[_c('v-tabs',{on:{"change":_vm.onTabChange},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Attive")]),_c('v-tab',[_vm._v("Scadute")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.adoptionsHeaders,"items":_vm.filteredAdoptions,"item-key":"id","items-per-page":10,"footer-props":{
                  itemsPerPageOptions: [10, 20, 30, -1],
                }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start align-center"},[_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","x-small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.onUserDetails(item.uid)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-account-details")])],1),_c('div',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.userFullName(item.uid)))]),_c('br')])],1)]}},{key:"item.hive",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start align-center"},[_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":"","fab":"","x-small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.onHiveDetails(item.hiveId)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-archive")])],1),_c('div',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.hiveName(item.hiveId)))]),_c('br'),_c('span',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm.apiaryName(item.apiaryId)))])])],1)]}},{key:"item.honeycombs",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.honeycombs)+" ")]}},{key:"item.progress",fn:function(ref){
                var item = ref.item;
return [_c('Progress',{staticClass:"mt-1",attrs:{"value":_vm.percent(item),"text":_vm.progressText(item),"color":_vm.progressColor(item),"dark":_vm.isEnding(item)}}),_c('span',{staticClass:"font-weight-light caption"},[_vm._v(" "+_vm._s(_vm.adoptionStartDate(item))+" - "+_vm._s(_vm.adoptionEndDate(item))+" ")])]}},{key:"item.delivered",fn:function(ref){
                var item = ref.item;
return [(item.honeycombs > 0)?_c('span',{staticClass:"font-weight-medium",class:item.delivered ? 'green--text' : 'red--text'},[_vm._v(" "+_vm._s(item.delivered ? '🥳 Sì' : '😱 No')+" ")]):_c('span',{staticClass:"font-weight-medium grey--text"},[_vm._v(" Non richiesto ")])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":item.honeycombs === 0},on:{"click":function($event){return _vm.onToggleDelivered(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.delivered === true ? 'Segna come non consegnato' : 'Segna come consegnato')+" ")])],1)],1)],1),_c('v-btn',{staticClass:"ml-1",attrs:{"fab":"","x-small":"","depressed":"","color":"secondary","disabled":"","dark":false},on:{"click":function($event){return _vm.onDetails(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-eye")])],1)],1)]}}],null,true)})],1),_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.expiredAdoptionsHeaders,"items":_vm.filteredExpiredAdoptions,"item-key":"id","items-per-page":10,"footer-props":{
                  itemsPerPageOptions: [10, 20, 30, -1],
                }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start align-center"},[_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","x-small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.onUserDetails(item.data.uid)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-account-details")])],1),_c('div',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.userFullName(item.data.uid)))]),_c('br')])],1)]}},{key:"item.hive",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start align-center"},[_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":"","fab":"","x-small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.onHiveDetails(item.data.hiveId)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-archive")])],1),_c('div',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.hiveName(item.data.hiveId)))]),_c('br'),_c('span',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm.apiaryName(item.data.apiaryId)))])])],1)]}},{key:"item.honeycombs",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.honeycombs)+" ")]}},{key:"item.deletedAt",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mDate(item.deletedAt.toDate()))+" ")]}},{key:"item.delivered",fn:function(ref){
                var item = ref.item;
return [(item.data.honeycombs > 0)?_c('span',{staticClass:"font-weight-medium",class:item.data.delivered ? 'green--text' : 'red--text'},[_vm._v(" "+_vm._s(item.data.delivered ? '🥳 Sì' : '😱 No')+" ")]):_c('span',{staticClass:"font-weight-medium grey--text"},[_vm._v(" Non richiesto ")])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":item.data.honeycombs === 0},on:{"click":function($event){return _vm.onToggleDelivered(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.data.delivered === true ? 'Segna come non consegnato' : 'Segna come consegnato')+" ")])],1)],1)],1),_c('v-btn',{staticClass:"ml-1",attrs:{"fab":"","x-small":"","depressed":"","color":"secondary","disabled":"","dark":false},on:{"click":function($event){return _vm.onDetails(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-eye")])],1)],1)]}}],null,true)})],1)],1)],1)]),_c('v-card-actions',{staticClass:"dialog-actions"},[_c('div',{staticClass:"d-none d-sm-flex flex-grow-1"},[_c('v-btn',{attrs:{"rounded":"","depressed":"","outlined":"","color":"#D19555","dark":""},on:{"click":_vm.onCancel}},[_vm._v(" Chiudi ")]),_c('v-spacer')],1),_c('div',{staticClass:"d-flex d-sm-none flex-grow-1"},[_c('v-btn',{attrs:{"small":"","rounded":"","depressed":"","outlined":"","color":"#D19555","dark":""},on:{"click":_vm.onCancel}},[_vm._v(" Chiudi ")]),_c('v-spacer')],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }